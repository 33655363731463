<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="true"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-scrollbar :native="false">
        <div class="tabPane">
          <el-form-item
            prop="variable_node.data.variable_rules.data"
            :label="__('Assign values to variables')"
          >
            <extract-words
              v-model="variableRules"
              :variables="singleValuedAndSecureVariables"
              :rule_title="__('Value')"
            />
          </el-form-item>
        </div>
      </el-scrollbar>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "./BaseNode";
import _ from "lodash";
import ExtractWords from "./components/ExtractWords";
import { filterRowsIfEveryKeyValueIsAbsent } from "@/utils/collection";
import { NODE_TYPES } from "@/constants/nodes";
import { variableRulesValidation } from "@/utils/formValidationRules";

export default {
  mixins: [BaseNode],
  components: {
    ExtractWords
  },
  data() {
    const validateVariableRules = (rule, value, callback) => {
      let validationResult = variableRulesValidation(rule, value, this);
      validationResult
        ? callback()
        : callback(__("variable rules configuration incomplete"));
      // if (validationResult === "secure_variable_used") {
      //   callback("Cannot set secure variable");
      // } else {
      //
      // }
    };
    // const validateEmptyRules = (rule, value, callback) => {
    //   isEmptyVariableRules(rule, value)
    //     ? callback("variable rules cannot be empty")
    //     : callback();
    // };
    return {
      rules: {
        "variable_node.data.variable_rules.data": [
          { validator: validateVariableRules }
          // { validator: validateEmptyRules }
        ]
      }
    };
  },
  computed: {
    variableRules: {
      get: function() {
        const { data: variable_rules } = _.cloneDeep(
          this.nodeToBind.variable_node.data.variable_rules
        );
        return _.isEmpty(variable_rules) ? [] : variable_rules;
      },

      set: function(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
        if (
          // update only if there is a change in word rules
          !_.isEqual(this.variableRules, val) ||
          _.isEmpty(this.variableRules)
        ) {
          this.$set(
            this.nodeToBind.variable_node.data.variable_rules,
            "data",
            val
          );
        }
        // this.isMounted
        //   ? this.$refs.nodeForm.validateField(
        //       "variable_node.data.variable_rules.data"
        //     )
        //   : null;
      }
    }
  },
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      const variableRules = filterRowsIfEveryKeyValueIsAbsent(
        _.map(this.variableRules, variableRule => {
          const {
            default_value,
            rule_value,
            variable_id,
            variable_name,
            error
          } = variableRule;
          return {
            default_value,
            rule_value: rule_value.toString().trim(),
            variable_id,
            variable_name: variable_name.toString().trim(),
            error
          };
        }),
        "rule_value,variable_name"
      );

      this.$set(
        nodeToCleanUp.variable_node.data.variable_rules,
        "data",
        variableRules
      );
      return nodeToCleanUp;
    },
    cleanUpNode() {
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.variable_node)) {
      this.$set(this.nodeToBind, "variable_node", {});
      this.$set(this.nodeToBind.variable_node, "data", {});

      if (_.isEmpty(this.nodeToBind.variable_node.data.variable_rules)) {
        this.$set(this.nodeToBind.variable_node.data, "variable_rules", {});
      }

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.VARIABLE.NODE_TYPE);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
